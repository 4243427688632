





























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";

import i18n from "@/plugins/i18n";
import { axiosInstance as axios } from "@/store/";

import Password from "@/components/inputs/Password.vue";
import PasswordNew from "@/components/inputs/PasswordNew.vue";

@Component({
  components: {
    Password,
    PasswordNew,
  },
})
export default class PasswordResetView extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  login = "";
  newPassword = "";
  newPasswordRepeat = "";

  loginRules = [
    (v: unknown): boolean | string => !!v || i18n.t("passwordReset.form.validation.login.required").toString(),
  ];

  passwordRepeatRules = [
    (v: string): boolean | string =>
      !!v || i18n.t("passwordReset.form.validation.newPasswordRepeat.required").toString(),
    (_v: string): boolean | string =>
      this.checkPasswordRepeat() || i18n.t("passwordReset.form.validation.newPasswordRepeat.notmatch").toString(),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  checkPasswordRepeat(): boolean {
    return this.newPassword === this.newPasswordRepeat;
  }

  async submit(): Promise<void> {
    if (this.$refs.form.validate()) {
      this.sending = true;
      try {
        await axios.post("/auth/user/confirmpasswordresettoken", {
          login: this.login,
          newPassword: this.newPassword,
          token: this.$router.currentRoute.params.token,
        });
        await this.$store.direct.dispatch.authentication.login({ login: this.login, password: this.newPassword });
        this.$store.direct.commit.SHOW_TOAST({ text: this.$t("passwordReset.notifications.done").toString() });
        this.$router.replace("/");
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("passwordReset.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 403) this.alert.message = this.$t("passwordReset.form.error.code403").toString();
          if (e.response?.status === 404) this.alert.message = this.$t("passwordReset.form.error.code404").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }
}
